<template>
  <div class="flex h-screen">
    <div class="w-1/3">
      <img
        src="@/assets/auth-bg.jpg"
        class="object-cover h-full w-full"
      >
    </div>
    <div class="w-2/3 p-16">
      <div class="flex flex-col justify-between h-full">
        <div class="text-gray-700 flex justify-center">
          <img
            class="w-1/3"
            src="@/assets/diskominfo-logo.png"
            alt=""
          >
        </div>
        <router-view />
        <div class="text-gray-700 text-center text-sm">
          Developed in Malang
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // data() {
  //   return {
  //     form: {
  //       username: null,
  //       password: null
  //     }
  //   }
  // },
  // methods: {
  //   doLogin() {

  //   }
  // }
}
</script>

<style>

</style>